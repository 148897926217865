// import { StrictMode } from "react";
// import { createRoot } from "react-dom/client";
// import { BrowserRouter } from "react-router-dom";
// import { AuthProvider } from "./AuthProvider"; // Import AuthProvider
// import "./index.css";
// import App from "./App";

// const rootElement = document.getElementById("root");
// const root = createRoot(rootElement);

// root.render(
//   <StrictMode>
//     <BrowserRouter>
//       <AuthProvider>
//         <App />
//       </AuthProvider>
//     </BrowserRouter>
//   </StrictMode>
// );

import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { AuthProvider } from "./AuthProvider"; // AuthProvider for authentication context
import "./index.css";
import App from "./App";

// Create root element
const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

// Render app without StrictMode
root.render(
  <BrowserRouter>
    <AuthProvider> {/* Authentication context provider */}
      <App />      {/* Main App component */}
    </AuthProvider>
  </BrowserRouter>
);

