import React, { useState } from 'react';
import { Card, Typography, TextField, Button, CircularProgress, Box, IconButton ,InputAdornment } from '@mui/material';
import { styled } from '@mui/system';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import Swal from 'sweetalert2';
import { useAuth } from "../../AuthProvider";
import { toast,ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';


const StyledCard = styled(Card)({
  width: '82.5vw',
  height: '85vh',
  margin: '2px auto',
  padding: '5px',
  boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
  position: 'relative',
});

const ContentBox = styled(Box)({
  width: '100%',
  maxWidth: '600px',
  textAlign: 'center',
  margin: 'auto',
  position: 'relative',
  top: '40%',
  transform: 'translateY(-50%)',
  padding: '20px', // Added padding for a cleaner look
  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', // Subtle shadow for depth
  borderRadius: '8px', // Rounded corners
});

const TitleBox = styled(Box)({
  position: 'relative',
  top: 0,
  left: 0,
  marginTop: '25px',
  paddingLeft: '20px',
});

const ChangePassword = () => {
  const [oldPassword, setOldPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const { companyEmailId } = useAuth();

  const handleChangePassword = async () => {
    setLoading(true);
    try {
      const url = `https://login.hrbppayroll.com/hrbp/api/users/change-password`;
  
      // Create a FormData object to send the data
      const formData = new FormData();
      formData.append('email', companyEmailId);
      formData.append('oldPassword', oldPassword);
      formData.append('newPassword', newPassword);
      formData.append('confirmPassword', confirmPassword);
  
      const response = await fetch(url, {
        method: 'PUT',
        headers: {
          // 'Content-Type': 'application/json' -> Do not set content type for FormData; it sets it automatically
        },
        body: formData, // Send the form data
      });
  
      const contentType = response.headers.get('content-type');
      let result;
      if (contentType && contentType.includes('application/json')) {
        result = await response.json();
      } else {
        result = await response.text(); // Handle plain text response
      }
  
      if (result.success || response.ok) {
        // Show success toast notification using toast.fire
        toast.success('Your password has been changed successfully.', {
          position: 'top-right',
          autoClose: 1000,
        });
      } else {
        // Show error toast notification using toast.fire
        toast.error('There was a problem changing your password.', {
          position: 'top-right',
          autoClose: 1000,
        });
      }
    } catch (error) {
      console.error('Error changing password:', error);
      // Show error toast notification using toast.fire
      toast.error('There was a problem changing your password.', {
        position: 'top-right',
        autoClose: 1000,
      });
    } finally {
      setLoading(false);
      // Reset password fields
      setOldPassword('');
      setNewPassword('');
      setConfirmPassword('');
    }
  };
  

  const toggleOldPasswordVisibility = () => setShowOldPassword(prev => !prev);
  const toggleNewPasswordVisibility = () => setShowNewPassword(prev => !prev);

  return (

    <div style={{ marginTop: '85px' }}>
    <StyledCard >
      <TitleBox>
        <Typography variant="h3" gutterBottom sx={{ fontWeight: 'bold' }}>
          Change Password
        </Typography>
      </TitleBox>
      <ContentBox>
  {/* Old Password Field */}
  <Box mb={3} textAlign="left">
    <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold', color: '#333' }}>
      Old Password:
    </Typography>
    <TextField
      fullWidth
      placeholder="Enter your old password"
      variant="outlined"
      type={showOldPassword ? 'text' : 'password'}
      value={oldPassword}
      onChange={(e) => setOldPassword(e.target.value)}
      // InputProps={{
      //   endAdornment: (
      //     <InputAdornment position="end">
      //       <IconButton onClick={toggleOldPasswordVisibility}>
      //         {showOldPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
      //       </IconButton>
      //     </InputAdornment>
      //   ),
      // }}
    />
  </Box>

  {/* New Password Field */}
  <Box mb={4} textAlign="left">
    <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold', color: '#333' }}>
      New Password:
    </Typography>
    <TextField
      fullWidth
      placeholder="Enter your new password"
      variant="outlined"
      type={showNewPassword ? 'text' : 'password'}
      value={newPassword}
      onChange={(e) => setNewPassword(e.target.value)}
      // InputProps={{
      //   endAdornment: (
      //     <InputAdornment position="end">
      //       <IconButton onClick={toggleNewPasswordVisibility}>
      //         {showNewPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
      //       </IconButton>
      //     </InputAdornment>
      //   ),
      // }}
    />
  </Box>
  <Box mb={4} textAlign="left">
    <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold', color: '#333' }}>
      Confirm Password:
    </Typography>
    <TextField
      fullWidth
      placeholder="Enter your new password Again"
      variant="outlined"
      type={showConfirmPassword ? 'text' : 'password'}
      value={confirmPassword}
      onChange={(e) => setConfirmPassword(e.target.value)}
      // InputProps={{
      //   endAdornment: (
      //     <InputAdornment position="end">
      //       <IconButton onClick={toggleNewPasswordVisibility}>
      //         {showNewPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
      //       </IconButton>
      //     </InputAdornment>
      //   ),
      // }}
    />
  </Box>

  {/* Change Password Button */}
  <Button
    variant="contained"
    onClick={handleChangePassword}
    disabled={loading}
    sx={{
      width: '200px',
      height: '40px',
      backgroundColor: '#7e31ce',
      color: '#fff',
      '&:hover': { backgroundColor: '#13A9BD' },
      textAlign: 'center',
      borderRadius: '20px',
    }}
  >
    {loading ? <CircularProgress size={24} color="inherit" /> : 'Change Password'}
  </Button>
</ContentBox>
    </StyledCard>
    <ToastContainer/>
    </div>
  );
};

export default ChangePassword;
