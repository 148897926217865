import React from 'react';
import Radar from 'radar-sdk-js';
import 'radar-sdk-js/dist/radar.css';
import { Card, CardContent, Typography, Select, MenuItem, FormControl, InputLabel } from '@mui/material';

class MapContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      radius: 50, // Default radius
      map: null,
      circleSourceId: 'circle-source', // A unique source ID for the circle layer
    };
  }

  componentDidMount() {
    // Initialize Radar SDK
    Radar.initialize('prj_live_pk_660eb23464aea78dde8fbef9404e32b89c955a18');

    // Create a map using Radar's UI
    const map = Radar.ui.map({
      container: 'map',
    });

    // Store the map in the state
    this.setState({ map });

    // Check if geolocation is available in the browser
    if (navigator.geolocation) {
      // Get the user's current position
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;

          // Center map on user's location
          map.setCenter([longitude, latitude]);
          map.setZoom(14);

          // Create a marker at the user's location
          const userMarker = Radar.ui.marker()
            .setLngLat([longitude, latitude])
            .addTo(map);

          // Add the circle layer for the user's location
          // this.addCircleLayer(map, longitude, latitude, this.state.radius);

          // Fit map to the markers (optional)
          map.fitToMarkers({ maxZoom: 14, padding: 80 });
        },
        (error) => {
          console.error('Error getting location:', error);
        }
      );
    } else {
      console.error('Geolocation is not supported by this browser.');
    }

    // // Optional: Add marker on map click
    // map.on('click', (e) => {
    //   const { lng, lat } = e.lngLat;

    //   // Create a marker from click location
    //   const marker = Radar.ui.marker().setLngLat([lng, lat]).addTo(map);

    //   // Fit map to markers
    //   map.fitToMarkers({ maxZoom: 14, padding: 80 });

    //   // Add circle around click location
    //   this.addCircleLayer(map, lng, lat, this.state.radius);

    //   // Add listener to remove marker on click
    //   marker.on('click', () => {
    //     marker.remove();
    //     map.fitToMarkers({ maxZoom: 14, padding: 80 }); // Refit after marker removed
    //   });
    // });
  }

  // Method to add circle layer on the map
  // addCircleLayer(map, lng, lat, radius) {
  //   // Remove previous circle layer and source if they exist
  //   if (map.getLayer('circle-layer')) {
  //     map.removeLayer('circle-layer');
  //   }
  //   if (map.getSource(this.state.circleSourceId)) {
  //     map.removeSource(this.state.circleSourceId);
  //   }

  //   // Add new source and layer for the circle
  //   map.addSource(this.state.circleSourceId, {
  //     type: 'geojson',
  //     data: {
  //       type: 'Feature',
  //       geometry: {
  //         type: 'Point',
  //         coordinates: [lng, lat],
  //       },
  //     },
  //   });

  //   // map.addLayer({
  //   //   id: 'circle-layer',
  //   //   type: 'circle',
  //   //   source: this.state.circleSourceId,
  //   //   paint: {
  //   //     'circle-radius': {
  //   //       stops: [
  //   //         [0, 0],
  //   //         [20, radius], // Radius in meters based on zoom level
  //   //       ],
  //   //     },
  //   //     'circle-color': '#ff0000', // Red color
  //   //     'circle-opacity': 0.5,
  //   //   },
  //   // });
  // }

  // handleRadiusChange = (event) => {
  //   const selectedRadius = event.target.value;
  //   this.setState({ radius: selectedRadius }, () => {
  //     // Get current map center and add new circle layer with updated radius
  //     const { map } = this.state;
  //     const center = map.getCenter();
  //     this.addCircleLayer(map, center.lng, center.lat, selectedRadius);
  //   });
  // };

  render() {
    return (
      <Card
        style={{
          height: '85vh',
          width: '81vw',
          margin: 'auto',
          padding: '8px',
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
          marginTop: '7%',
        }}
      >
        <CardContent style={{ height: '100%' }}>
          <Typography variant="h6" align="center" gutterBottom style={{ fontWeight: 'bold', fontSize: '20px' }}>
            Map View - Your Location
          </Typography>
{/* 
          <FormControl fullWidth variant="outlined" style={{ marginBottom: '16px' }}>
            <InputLabel id="radius-select-label">Select Range</InputLabel>
            <Select
              labelId="radius-select-label"
              id="radius-select"
              value={this.state.radius}
              onChange={this.handleRadiusChange}
              label="Select Range"
            >
              <MenuItem value={50}>50 meters</MenuItem>
              <MenuItem value={100}>100 meters</MenuItem>
              <MenuItem value={150}>150 meters</MenuItem>
              <MenuItem value={200}>200 meters</MenuItem>
            </Select>
          </FormControl> */}

          <div id="map-container" style={{ height: 'calc(100% - 40px)', position: 'relative' }}>
            <div id="map" style={{ width: '100%', height: '100%', position: 'absolute' }}></div>
          </div>
        </CardContent>
      </Card>
    );
  }
}

export default MapContainer;
