import React, { useState, useEffect } from "react";
import {
  Box,
  Card,
  CardContent,
  Typography,
  Grid,
  Table,
  CircularProgress,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Avatar,
  TablePagination,
} from "@mui/material";
import "./dashboard.css";
import { useNavigate } from "react-router-dom";
// import CheckCircleIcon from '@mui/icons-material/CheckCircle';
// import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Divider,
  TextField
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { GlobalStyles } from "@mui/system";
import GroupIcon from '@mui/icons-material/Group';
import EmojiPeopleIcon from '@mui/icons-material/EmojiPeople';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { TimePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { format } from 'date-fns'; // Import the format function
import { useAuth } from "../../AuthProvider";


const Dashboard = () => {
  const [employeeCount, setEmployeeCount] = useState(0);
  const [presentsCount, setPresentsCount] = useState(0);
  const [attendance, setAttendance] = useState([]);
  const [loader, setLoader] = useState(true);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  
  const navigate = useNavigate();
  const [isEditingCheckIn, setIsEditingCheckIn] = useState(false); // State to toggle check-in input field
  const [isEditingCheckOut, setIsEditingCheckOut] = useState(false); // State to toggle check-out input field
 
  const {organizationId}=useAuth()

//dialog open code
  const [open, setOpen] = useState(false);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [checkIn, setCheckIn] = useState(selectedEmployee?.checkIn || ''); // Initial check-in time
  const [checkOut, setCheckOut] = useState(selectedEmployee?.checkOut || '');
  const [dateId, setDateId] = useState([]);
  const [timeoutId, setTimeoutId] = useState(null); // To store the timeout ID

  const handleOpenModal = (employee) => {
    setSelectedEmployee(employee);
    setDateId(employee.id)
    setOpen(true);
  };

  const handleCloseModal = () => {
    setOpen(false);
    setSelectedEmployee(null);
  };




  const getTodayDate = () => {
    const today = new Date();
    const yyyy = today.getFullYear();
    const mm = String(today.getMonth() + 1).padStart(2, "0");
    const dd = String(today.getDate()).padStart(2, "0");
    return `${yyyy}-${mm}-${dd}`;
  };

  const todayDate = getTodayDate();

  useEffect(() => {
    const fetchEmployeeCount = async () => {
      try {
        const response = await fetch(
          `https://login.hrbppayroll.com/hrbp/api/employee/count/by-organisation/${organizationId}`
        );
        const data = await response.json();
        setEmployeeCount(data);
      } catch (error) {
        console.error("Failed to fetch employee count", error);
      }
    };

    fetchEmployeeCount();
  }, []);

  useEffect(() => {
    const fetchPresentsCount = async () => {
      try {
        const response = await fetch(
          ` https://login.hrbppayroll.com/hrbp/api/attendance/employees/count/${todayDate}/${organizationId}`
        );
        const data = await response.json();
        setPresentsCount(data);
      } catch (error) {
        console.error("Failed to fetch presents count", error);
      }
    };

    fetchPresentsCount();
  }, [todayDate]);

  const totalAbsent = employeeCount - presentsCount;

  useEffect(() => {
    const fetchAllAttendance = async () => {
      try {
        setLoader(true);
        const response = await fetch(
          `https://login.hrbppayroll.com/hrbp/api/attendance/employees/all/${todayDate}/${organizationId}`
        );
        const data = await response.json();
  
        const todayAttendace = data.filter(
          (employee) => employee.date === todayDate
        );
        const employeesWithImages = todayAttendace.map((employee) => ({
          ...employee,
          checkInPic: `data:image/jpeg;base64,${employee.checkInPic}`,
          checkOutPic: `data:image/jpeg;base64,${employee.checkOutPic}`
        }));
  
        // Sort employeesWithImages so newly added rows appear on top
        const sortedAttendance = employeesWithImages.reverse();
  
        // Extract all IDs and set them in the state
        
        setAttendance(sortedAttendance);
      } catch (error) {
        console.error("Failed to fetch attendance", error);
      } finally {
        setLoader(false);
      }
    };
  
    fetchAllAttendance();
  }, [todayDate]);
  

  //for punch in location
  const truncateString = (str, num) => {
    if (typeof str !== "string") {
      return "Punch In Location Not Found";
    }
    if (str.length <= num) {
      return str;
    }
    return str.slice(0, num) + "...";
  };
  //for punch out location
  const truncateString1 = (str, num) => {
    if (typeof str !== "string") {
      return "Punch Out Location Not Found";
    }
    if (str.length <= num) {
      return str;
    }
    return str.slice(0, num) + "...";
  };


  // const formatTimeFromArray = (timeArray) => {
  //   if (Array.isArray(timeArray) && timeArray.length >= 2) {
  //     const hours = String(timeArray[0]).padStart(2, "0");
  //     const minutes = String(timeArray[1]).padStart(2, "0");
  //     return `${hours}:${minutes}`;
  //   }
  //   return "N/A";
  // };

  // const attendanceNavigation = () => {
  //   navigate("/employeeAttendance");
  // };

  const employeeClick = () => {
    navigate("/employee");
  };

  const handleAbsents = () => {
    navigate("/absentsList");
  };

  const handlePresents = () => {
    navigate("/employeeAttendance");
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };



// Function to handle API call for updating punch details
 const updatePunchDetails = async (updatedData) => {
    // Format checkIn and checkOut to 'hh:mm a' format for proper API sending
    const formattedCheckIn = checkIn ? format(new Date(checkIn), 'hh:mm a') : selectedEmployee?.checkIn;
    const formattedCheckOut = updatedData.checkOut ? updatedData.checkOut : selectedEmployee?.checkOut; // Ensure it's the correctly formatted value
  
    const url = `https://login.hrbppayroll.com/hrbp/api/attendance/time/${dateId}?checkIn=${formattedCheckIn}&checkOut=${formattedCheckOut}`;
  
    const options = {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
    };
  
    try {
      const response = await fetch(url, options);
  
      if (response.ok) {
        const data = await response.json();
        console.log('Punch details updated successfully:', data);
  
        toast.success('Punch details updated successfully!', {
          position: 'top-right',
          autoClose: 1000, // Close after 1 second
        });
        setOpen(false);
        return data;
      } else {
        const errorData = await response.json();
        console.error('Error updating punch details:', errorData);
  
        toast.error('Error updating punch details', { position: 'top-right' });
  
        throw new Error('Error updating punch details');
      }
  
    } catch (error) {
      console.error('Error during API call:', error);
  
      toast.error('Error during API call', { position: 'top-right' });
  
      throw new Error('Error during API call');
    }
  };

  // Handle the check-in update
const handleCheckInBlur = () => {
  const updatedData = {
    checkIn: checkIn || selectedEmployee.checkIn,
    checkOut: checkOut || selectedEmployee.checkOut, // Send both values
  };
  updatePunchDetails(updatedData);
  setIsEditingCheckIn(false);
};

const handleCheckOutBlur = () => {
    // If there's a previous timeout, clear it
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
  
    // Set a new timeout to delay the API call by 1 second (1000ms)
    const newTimeoutId = setTimeout(async () => {
      // Log the raw checkOut value
      console.log('checkOut before formatting:', checkOut); 
      
      // Ensure checkOut is a valid Date object
      const checkOutDate = new Date(checkOut);
      console.log('checkOut as Date:', checkOutDate); 
  
      // If the time is in the AM and you need PM, forcefully update it
      if (checkOutDate.getHours() < 12) { // if it's AM (0-11 hours)
        checkOutDate.setHours(checkOutDate.getHours() + 12); // Convert to PM
      }
      
      // Format the time in 'hh:mm aa' format (e.g., '06:30 PM')
      const formattedCheckOut = checkOut ? format(checkOutDate, 'hh:mm aa') : 'N/A'; 
      console.log('Formatted CheckOut Time:', formattedCheckOut); 
  
      const updatedData = {
        checkIn: checkIn ? format(new Date(checkIn), 'hh:mm aa') : selectedEmployee.checkIn,
        checkOut: formattedCheckOut || selectedEmployee.checkOut,
      };
  
      try {
        await updatePunchDetails(updatedData); // Call the API with formatted data
        setIsEditingCheckOut(false); // Close the editor
      } catch (error) {
        console.error('Error updating punch details:', error);
      }
    }, 1000); // 1 second delay
  
    setTimeoutId(newTimeoutId); // Save the timeout ID to clear if needed
  };

  return (
    <Box p={3} marginTop={9}>
      <Grid container spacing={3}>
  <Grid item xs={12} sm={4} onClick={employeeClick}>
    <Card
      sx={{
        height: "80px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        background: "#529CAD", // Minimal color
        color: "#fff",
        boxShadow: "0px 8px 15px rgba(0, 0, 0, 0.3)", // Enhanced shadow
        borderRadius: "12px", // Smooth rounded corners
        transition: "transform 0.3s ease-in-out", // Animation for hover effect
        cursor: "pointer",
        "&:hover": {
          transform: "translateY(-10px)", // Hover effect
          boxShadow: "0px 12px 20px rgba(0, 0, 0, 0.4)", // Deep shadow on hover
        },
      }}
    >
      <CardContent sx={{ textAlign: "center", width: "100%" }}>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          flexDirection={{ xs: "row", sm: "row" }}
        >
          <GroupIcon
            sx={{
              color: "#fff",
              fontSize: { xs: 35, sm: 40 }, // Larger icon
              marginLeft: { xs: 2, sm: 2 },
              marginRight: { xs: 2 },
            }}
          />
          <Box textAlign="right">
            <Typography
              variant="h6"
              sx={{
                fontSize: { xs: "body2", sm: "h6" },
                fontWeight: "bold", // Bold title
                marginRight: "20px",
              }}
            >
              Employees
            </Typography>
            <Typography
              variant="h4"
              sx={{
                fontSize: { xs: "h6", sm: "h4" },
                fontWeight: "bold", // Bold count
                marginRight: "50px",
              }}
            >
              {employeeCount}
            </Typography>
          </Box>
        </Box>
      </CardContent>
    </Card>
  </Grid>

  <Grid item xs={12} sm={4}>
    <Card
      sx={{
        height: "80px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        background: "#529CAD", // Minimal color
        color: "#fff",
        boxShadow: "0px 8px 15px rgba(0, 0, 0, 0.3)", // Enhanced shadow
        borderRadius: "12px", // Smooth rounded corners
        transition: "transform 0.3s ease-in-out", // Animation for hover effect
        cursor: "pointer",
        "&:hover": {
          transform: "translateY(-10px)", // Hover effect
          boxShadow: "0px 12px 20px rgba(0, 0, 0, 0.4)", // Deep shadow on hover
        },
      }}
      onClick={handlePresents}
    >
      <CardContent sx={{ textAlign: "center", width: "100%" }}>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          flexDirection={{ xs: "row", sm: "row" }}
        >
          <EmojiPeopleIcon
            sx={{
              color: "#fff",
              fontSize: { xs: 35, sm: 40 },
              marginLeft: { xs: 2, sm: 2 },
              marginRight: { xs: 2 },
            }}
          />
          <Box textAlign="right">
            <Typography
              variant="h6"
              sx={{
                fontSize: { xs: "body2", sm: "h6" },
                fontWeight: "bold",
                marginRight: "20px",
              }}
            >
              Presents
            </Typography>
            <Typography
              variant="h4"
              sx={{
                fontSize: { xs: "h6", sm: "h4" },
                fontWeight: "bold",
                marginRight: "40px",
              }}
            >
              {presentsCount}
            </Typography>
          </Box>
        </Box>
      </CardContent>
    </Card>
  </Grid>

  <Grid item xs={12} sm={4}>
    <Card
      sx={{
        height: "80px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        background: "#529CAD", // Minimal color
        color: "#fff",
        boxShadow: "0px 8px 15px rgba(0, 0, 0, 0.3)", // Enhanced shadow
        borderRadius: "12px", // Smooth rounded corners
        transition: "transform 0.3s ease-in-out", // Animation for hover effect
        cursor: "pointer",
        "&:hover": {
          transform: "translateY(-10px)", // Hover effect
          boxShadow: "0px 12px 20px rgba(0, 0, 0, 0.4)", // Deep shadow on hover
        },
      }}
      onClick={handleAbsents}
    >
      <CardContent sx={{ textAlign: "center", width: "100%" }}>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          flexDirection={{ xs: "row", sm: "row" }}
        >
          <RemoveCircleOutlineIcon
            sx={{
              color: "#fff",
              fontSize: { xs: 35, sm: 40 },
              marginLeft: { xs: 2, sm: 2 },
              marginRight: { xs: 2 },
            }}
          />
          <Box textAlign="right">
            <Typography
              variant="h6"
              sx={{
                fontSize: { xs: "body2", sm: "h6" },
                fontWeight: "bold",
                marginRight: "20px",
              }}
            >
              Absents
            </Typography>
            <Typography
              variant="h4"
              sx={{
                fontSize: { xs: "h6", sm: "h4" },
                fontWeight: "bold",
                marginRight: "40px",
              }}
            >
              {totalAbsent}
            </Typography>
          </Box>
        </Box>
      </CardContent>
    </Card>
  </Grid>
</Grid>



      <Box mt={5}>
        <Card
          sx={{
            backgroundColor: "white",
            boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.2)",
          }}
        >
          <Typography sx={{ fontSize: 18, padding: 1, textAlign: "center" }}>
            <strong style={{ padding: 12 }}>Daily Employee Attendance</strong>
          </Typography>
          <CardContent>



          <GlobalStyles
          styles={{
            '.custom-sidebar-scrollbar': {
              scrollbarWidth: 'thin', // For Firefox
              scrollbarColor: 'grey #e0e0e0',
            },
            '.custom-sidebar-scrollbar::-webkit-scrollbar': {
              width: '6px', // For WebKit-based browsers
            },
            '.custom-sidebar-scrollbar::-webkit-scrollbar-track': {
              background: '#f1f1f1', // Track color
            },
            '.custom-sidebar-scrollbar::-webkit-scrollbar-thumb': {
              backgroundColor: 'grey', // Thumb color
              borderRadius: '10px',
            },
            '.custom-sidebar-scrollbar::-webkit-scrollbar-thumb:hover': {
              backgroundColor: '#555',
            },
          }}
        />
            <TableContainer component={Paper} style={{ maxHeight: '500px', overflowY: 'auto' }} className="custom-sidebar-scrollbar">
              <Table stickyHeader>
                <TableHead className="tableHeader">
                  <TableRow>
                  <TableCell
                      style={{
                        color: "white",
                        fontSize: "17px",
                        textAlign: "center",
                        fontWeight: "bold",
                        border: "1px solid black",
                         backgroundColor:'#529CAD'
                        // textTransform: "uppercase",
                      }}
                    >
                      S.No
                    </TableCell>
                    <TableCell
                      style={{
                        color: "white",
                        fontSize: "17px",
                        textAlign: "center",
                        fontWeight: "bold",
                        border: "1px solid black",
                         backgroundColor:'#529CAD'
                        // textTransform: "uppercase",
                      }}
                    >
                      Thumbnail
                    </TableCell>
                   
                    <TableCell
                      style={{
                        color: "white",
                        fontSize: "17px",
                        textAlign: "center",
                        fontWeight: "bold",
                        border: "1px solid black",
                         backgroundColor:'#529CAD'
                        // textTransform: "uppercase",
                      }}
                    >
                      Employee Name
                    </TableCell>
                    <TableCell
                      style={{
                        color: "white",
                        fontSize: "17px",
                        textAlign: "center",
                        fontWeight: "bold",
                        border: "1px solid black",
                         backgroundColor:'#529CAD'
                        // textTransform: "uppercase",
                      }}
                    >
                      Date
                    </TableCell>
                    <TableCell
                      style={{
                        color: "white",
                        fontSize: "17px",
                        textAlign: "center",
                        fontWeight: "bold",
                        border: "1px solid black",
                         backgroundColor:'#529CAD'
                        // textTransform: "uppercase",
                      }}
                    >
                      Punch In Time
                    </TableCell>
                    <TableCell
                      style={{
                        color: "white",
                        fontSize: "17px",
                        textAlign: "center",
                        fontWeight: "bold",
                        border: "1px solid black",
                         backgroundColor:'#529CAD'
                        // textTransform: "uppercase",
                      }}
                    >
                      Punch Out Time
                    </TableCell>
                    <TableCell
                      style={{
                        color: "white",
                        fontSize: "17px",
                        textAlign: "center",
                        fontWeight: "bold",
                        border: "1px solid black",
                         backgroundColor:'#529CAD'
                        // textTransform: "uppercase",
                      }}
                    >
                      Location
                    </TableCell>
                    <TableCell
                      style={{
                        color: "white",
                        fontSize: "17px",
                        textAlign: "center",
                        fontWeight: "bold",
                        border: "1px solid black",
                         backgroundColor:'#529CAD'
                        // textTransform: "uppercase",
                      }}
                    >
                      Status
                    </TableCell>
                  </TableRow>
                </TableHead>

               
                  <TableBody>
                    {loader ? (
                                                                    <TableRow>
                                                                      <TableCell colSpan={8} style={{ textAlign: "center", padding: "20px",fontSize:'15px' }}>
                                                                        Loading Data...
                                                                      </TableCell>
                                                                    </TableRow>
                                                                  ) : attendance.length === 0 ? (
                                                                    <TableRow>
                                                                      <TableCell colSpan={8} style={{ textAlign: "center", padding: "20px" }}>
                                                                        No data available
                                                                      </TableCell>
                                                                    </TableRow>
                                                                  ) : (
                    attendance
                      // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map((employee,index) => (
                        <TableRow key={employee.id}>
                          <TableCell
                            className="tableCell"
                            style={{
                              color: "black",
                              fontSize: "15px",
                              textAlign: "center",
                              border: "1px solid black",
                            }}
                          >
                            {index+1}
                          </TableCell>
                          <TableCell
                            className="tableCell"
                            style={{
                              border: "1px solid black",
                              textAlign:'center',
                              fontWeight:"bold",
                              cursor:'pointer',
                            }}
                          >
                            <Avatar
                              alt={employee.firstName}
                              style={{marginLeft:'29%', marginBottom:'10px'}}
                              src={employee.checkInPic}
                              onClick={() => handleOpenModal(employee)}
                            
                            />
                            {employee.employeeId}
                          </TableCell>
                          <TableCell
                            className="tableCell"
                            style={{
                              color: "black",
                              fontSize: "15px",
                              textAlign: "center",
                              border: "1px solid black",
                            }}
                          >
                            {employee.firstName} {employee.lastName}
                          </TableCell>
                          <TableCell
                            className="tableCell"
                            style={{
                              color: "black",
                              fontSize: "15px",
                              textAlign: "center",
                              border: "1px solid black",
                            }}
                          >
                            {employee.date}
                          </TableCell>
                          <TableCell
                            className="tableCell"
                            style={{
                              color: "black",
                              fontSize: "15px",
                              textAlign: "center",
                              border: "1px solid black",
                            }}
                          >
                            {/* {employee.checkIn} */}
                            {(employee.checkIn) || 'Not Punched in Yet'}
                          </TableCell>
                          <TableCell
                            className="tableCell"
                            style={{
                              color: "black",
                              fontSize: "15px",
                              textAlign: "center",
                              border: "1px solid black",
                            }}
                          >
                            {/* {employee.checkOut}  */}
                            {(employee.checkOut) || 'Not Punched out Yet'}
                          </TableCell>

                          
                          {/* <TableCell
                            className="tableCell"
                            style={{
                              color: "black",
                              fontSize: "15px",
                              textAlign: "center",
                              border: "1px solid black",
                              padding: 0,  // Remove padding from the cell
                              verticalAlign: "top",  // Ensure content is aligned to the top
                              width:'30%'
                            }}
                          >
                            <Box
                              display="flex"
                              flexDirection="column"
                              justifyContent="center"
                              alignItems="center"
                              width="100%" // Ensure the box takes up the full width
                            >
                              
                              <Box
                                style={{
                                  width: "100%",
                                  maxHeight: "50px", // Set a max height for the check-in section
                                  overflowY: "auto", // Enable vertical scrolling if content overflows
                                  padding: "8px 0 8px 0",
                                }}
                              >
                                <Typography variant="body2" style={{ fontSize: "15px" }}>
                                  {truncateString(employee.checkinLocation, 300)}
                                </Typography>
                              </Box>

                          
                              <Box
                                component="hr"
                                sx={{
                                  width: "100%",
                                  border: "none",           // Remove default border
                                  height: "1px",            // Set line height to 1px for thinness
                                  backgroundColor: "black", // Set the line color to black
                                  marginTop: 1.5,                // Remove any margin
                                }}
                              />

                              
                              <Box
                                style={{
                                  width: "100%",
                                  maxHeight: "50px", // Set a max height for the check-out section
                                  overflowY: "auto", // Enable vertical scrolling if content overflows
                                  padding: "8px 0",
                                }}
                              
                              >
                                <Typography variant="body2" style={{ fontSize: "15px" }}>
                                  {truncateString1(employee.checkoutLocation, 300)}
                                </Typography>
                              </Box>
                            </Box>
                          </TableCell> */}

                          <TableCell
  className="tableCell"
  style={{
    color: "black",
    fontSize: "15px",
    textAlign: "center",
    border: "1px solid black",
    padding: 0,  // Remove padding from the cell
    verticalAlign: "top",  // Ensure content is aligned to the top
    width: '30%'
  }}
>
  <Box
    display="flex"
    flexDirection="column"
    justifyContent="center"
    alignItems="center"
    width="100%" // Ensure the box takes up the full width
  >
    {/* Check-in location */}
    <Box
      className="custom-sidebar-scrollbar" // Add the custom scrollbar class
      style={{
        width: "100%",
        maxHeight: "50px", // Set a max height for the check-in section
        overflowY: "auto", // Enable vertical scrolling if content overflows
        padding: "8px 0",
      }}
    >
      <Typography variant="body2" style={{ fontSize: "13px" }}>
        {truncateString(employee.checkinLocation, 300)}
      </Typography>
    </Box>

    {/* Divider Line */}
    <Box
      component="hr"
      sx={{
        width: "100%",
        border: "none", // Remove default border
        height: "1px", // Set line height to 1px for thinness
        backgroundColor: "black", // Set the line color to black
        marginTop: 1.5, // Remove any margin
      }}
    />

    {/* Check-out location */}
    <Box
      className="custom-sidebar-scrollbar" // Add the custom scrollbar class
      style={{
        width: "100%",
        maxHeight: "50px", // Set a max height for the check-out section
        overflowY: "auto", // Enable vertical scrolling if content overflows
        padding: "8px 0",
      }}
    >
      <Typography variant="body2" style={{ fontSize: "13px" }}>
        {truncateString1(employee.checkoutLocation, 300)}
      </Typography>
    </Box>
  </Box>
</TableCell>


                          <TableCell
                            className="tableCell"
                            style={{
                              color: "green",
                      fontSize: "17px",
                      textAlign: "center",
                      fontWeight: "bold",
                      border: "1px solid black",
                            }}
                          >
                           {employee.status === "Employee" || employee.status === "Admin" || employee.status === "Device" ? "Present" : "Absent"}
                          </TableCell>
                        </TableRow>
                      ))
                      )}
                  </TableBody>

              </Table>
            </TableContainer>
           
          </CardContent>
        </Card>
      </Box>

      <Dialog open={open} onClose={handleCloseModal} maxWidth="md" fullWidth>
  <DialogTitle>
    {/* <strong style={{ fontSize: '20px' }}>Punch Details</strong> */}
    <strong style={{ fontSize: '20px' }}>Punch Details - 
      
    <strong style={{ color:'#7e31ce' }}> {selectedEmployee?.firstName} {selectedEmployee?.lastName} </strong>
    </strong>
    <IconButton
  aria-label="close"
  onClick={handleCloseModal}
  sx={{
    position: "absolute",
    right: 8,
    top: 8,
    color: "black", // Change the color to your desired value
  }}
>
  <CloseIcon />
</IconButton>

  </DialogTitle>
 
  <DialogContent >
    {selectedEmployee && (
      <Box
      
        gap={2}
      >
       
        {/* Punch In Details */}
        <Card sx={{ maxWidth: 900 }}>
  <strong style={{ fontSize: '15px', padding: '20px', color: '#0c8091' }}>
    Punch In Details
  </strong>
  <CardContent>
    <Box display="flex" flexDirection="row" alignItems="center" gap={2}>
      <Avatar
        alt="Punch In Photo"
        src={selectedEmployee.checkInPic}
        sx={{ width: 150, height: 150 }}
      />
      <Box>
        <Typography
          variant="h6"
          style={{ fontWeight: 'bold', paddingLeft: '25px', color: '#7e31ce' }}
        >
          Punch In Time:
        </Typography>
                    {isEditingCheckIn ? (
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <TimePicker
                  value={checkIn}
                  onChange={(newValue) => setCheckIn(newValue)} // Update time value
                  onAccept={() => handleCheckInBlur()} // Trigger API call when user clicks OK
                  onBlur={() => handleCheckInBlur()} // Trigger API call when user clicks outside
                  ampm // Enable AM/PM format
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      sx={{ paddingLeft: '25px' }}
                      autoFocus
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                          handleCheckInBlur(); // Trigger API call on "Enter" key press
                        }
                      }}
                      // Allow manual input in the time field
                      value={checkIn || ''} // Display the value, empty string if no value
                      onChange={(e) => setCheckIn(e.target.value)} // Handle manual input change
                    />
                  )}
                />
              </LocalizationProvider>
            ) : (
              <Typography
                variant="h6"
                style={{
                  fontWeight: 'bold',
                  paddingLeft: '25px',
                  color: 'Green',
                  paddingTop: 5,
                  cursor: 'pointer',
                }}
                onClick={() => setIsEditingCheckIn(true)} // Show input field on click
              >
                {selectedEmployee.checkIn || 'N/A'}
              </Typography>
            )}




        <Typography
          variant="h6"
          style={{ fontWeight: 'bold', paddingLeft: '25px', color: '#7e31ce' }}
          mt={2}
        >
          Punch In Address:
        </Typography>
        <Typography style={{ paddingLeft: '25px', paddingTop: 5 }}>
          {truncateString(selectedEmployee.checkinLocation, 300)}
        </Typography>
      </Box>
    </Box>
  </CardContent>
</Card>


        {/* Divider */}
        <Divider sx={{ width: "100%", marginY: 2 }} />

        {/* Punch Out Details */}
        <Card sx={{ maxWidth: 900, boxShadow: 1 }}>
  <strong style={{ fontSize: '15px', padding: '20px', color: '#0c8091' }}>
    Punch Out Details
  </strong>
  <CardContent>
    <Box display="flex" flexDirection="row" alignItems="center" gap={2}>
      <Avatar
        alt="Punch Out Photo"
        src={selectedEmployee.checkOutPic}
        sx={{ width: 150, height: 150 }}
      />
      <Box>
      <Typography
          variant="h6"
          style={{ fontWeight: 'bold', paddingLeft: '25px', color: '#7e31ce' }}
        >
          Punch Out Time:
        </Typography>
        {/* Editable Punch Out Time */}
        {isEditingCheckOut ? (
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <TimePicker
              value={checkOut}
              onChange={(newValue) => setCheckOut(newValue)} // Update Punch Out Time
              onAccept={() => handleCheckOutBlur()} // Trigger API call on Accept
              ampm // Enable AM/PM format
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  sx={{ paddingLeft: '25px' }}
                  autoFocus
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      handleCheckOutBlur(); // Trigger API call on "Enter" key press
                    }
                  }}
                />
              )}
            />
          </LocalizationProvider>
        ) : (
          <Typography
            variant="h6"
            style={{
              fontWeight: 'bold',
              paddingLeft: '25px',
              color: 'Green',
              cursor: 'pointer',
            }}
            onClick={() => setIsEditingCheckOut(true)} // Show TimePicker on click
          >
            {selectedEmployee.checkOut || 'N/A'}
          </Typography>
        )}

        {/* Punch Out Address */}
        <Typography variant="h6" style={{ fontWeight: 'bold', paddingLeft: '25px', color: '#7e31ce' }} mt={2}>
          Punch Out Address:
        </Typography>
        <Typography style={{ paddingLeft: '25px', paddingTop: 5 }}>
          {truncateString(selectedEmployee.checkoutLocation, 300)}
        </Typography>
      </Box>
    </Box>
  </CardContent>
</Card>

      </Box>
    )}
  </DialogContent>
  
</Dialog>
<ToastContainer/>

    </Box>
  );
};

export default Dashboard;