import React, { useState,useEffect } from "react";
import {
  Box,
  IconButton,
  Menu,
  MenuItem,
  Avatar,
  Tooltip,
  Typography
} from "@mui/material";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import HttpsIcon from '@mui/icons-material/Https';
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { useProSidebar } from "react-pro-sidebar";
import { useNavigate } from "react-router-dom";
import { useCompany } from "../CompanyContext/CompanyContext";


import { useAuth } from "../../AuthProvider";

const Topbar = ({ showSearchInput }) => {
  const { toggleSidebar, broken, rtl } = useProSidebar();
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();
  const { companyEmailId,organizationId } = useAuth();
  const { companyInfo, setCompanyInfo } = useCompany(); 
  const [loading, setLoading] = useState(true);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  // Log Out - Delete Method
  const handleDelete = async () => {
    try {
      const response = await fetch(`https://login.hrbppayroll.com/hrbp/api/v1/auth/logout`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (response.ok) {
        localStorage.removeItem('companyEmailId');
        navigate("/");
      } else {
        console.error('Error during logout');
      }
    } catch (error) {
      console.error('Error during logout:', error);
    }
  };

   useEffect(() => {
      const fetchCompanyInfo = async () => {
        try {
          const response = await fetch(`https://login.hrbppayroll.com/hrbp/api/organisation/${organizationId}`);
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          const data = await response.json();
    
          // Ensure data is not null and has the expected properties
          if (data && data.companyLogo && data.companyName) {
            setCompanyInfo({
              companyLogo: data.companyLogo,
              companyName: data.companyName,
            });
          } else {
            console.warn("Company logo or name is missing from the response data.");
            
          }
          setLoading(false); // Stop loading when data is fetched
        } catch (error) {
          console.error("Error fetching company info:", error);
          setLoading(false); // Stop loading even if there's an error
        }
      };
    
      fetchCompanyInfo();
    }, [setCompanyInfo]);

  const handleProfile = () => {
    navigate("/profile");
    handleMenuClose();
  };

  const handlePassword = () => {
    navigate("/changePassword");
    handleMenuClose();
  };

  const handleSetting = () => {
    navigate("/ReportTimings");
    handleMenuClose();
  };

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      
      bgcolor="#E0F5F3"
      sx={{
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        zIndex: 1100,
        boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
        borderBottom: "2px solid #13A9BD", // Added a subtle border
      }}
    >
      <Box display="flex">
        {broken && !rtl && (
          <IconButton
            sx={{
              margin: "0 6px 0 2px",
              transition: "transform 0.3s ease", // Smooth transition
              '&:hover': {
                transform: 'scale(1.1)', // Slight hover effect
              },
            }}
            onClick={() => toggleSidebar()}
          >
            <MenuOutlinedIcon sx={{ fontSize: 28, color: "#13A9BD" }} />
          </IconButton>
        )}
      </Box>

      <Box display="flex" alignItems="center" sx={{ padding: '8px', justifyContent: 'flex-end' }}>
  {/* Tooltip for Profile icon */}
  <Box display="flex" alignItems="center" justifyContent="space-between" width="100%" flexDirection="row-reverse">
 
  <Tooltip title="Account Settings" arrow>
    <IconButton onClick={handleMenuOpen}>
      <Avatar
        sx={{
          bgcolor: "#13A9BD",
          color: "#fff",
          fontSize: 36,
          transition: "all 0.3s ease-in-out",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
          '&:hover': {
            transform: 'scale(1.1)',
            backgroundColor: '#0a7f87',
          },
        }}
      >
        <PersonOutlinedIcon />
      </Avatar>
    </IconButton>
  </Tooltip>
  <Box textAlign="right">
    <Typography
      variant="h6"
      sx={{ fontSize: "15px", fontWeight: "bold", color: "black" }}
    >
      Welcome!
    </Typography>
    <Typography
      variant="subtitle1"
      sx={{ color: "#7e31ce", fontSize: "13px", fontWeight: "bold" }}
    >
      {companyInfo.companyName}
    </Typography>
  </Box>
</Box>





  <Menu
    anchorEl={anchorEl}
    open={Boolean(anchorEl)}
    onClose={handleMenuClose}
    sx={{
      boxShadow: 3,
      borderRadius: 2,
      '& .MuiPaper-root': {
        borderRadius: '8px',
        backgroundColor: '#fff',
        boxShadow: '0 4px 16px rgba(0, 0, 0, 0.1)',
        border: '1px solid #E0E0E0',
      },
      '& .MuiMenuItem-root': {
        borderRadius: 1,
        transition: 'all 0.3s ease-in-out',
        '&:hover': {
          backgroundColor: '#E5F6F7',
          color: '#13A9BD',
          transform: 'scale(1.05)',
        },
      },
    }}
  >
    <MenuItem
      onClick={handleProfile}
      sx={{
        fontSize: 16,
        fontWeight: '600',
        padding: '12px 20px',
        display: 'flex',
        alignItems: 'center',
        '&:hover': {
          backgroundColor: '#E5F6F7',
          color: '#13A9BD',
        },
      }}
    >
      <AccountCircleIcon sx={{ marginRight: 1, color: '#13A9BD' }} />
      Profile
    </MenuItem>
    <MenuItem
      onClick={handlePassword}
      sx={{
        fontSize: 16,
        fontWeight: '600',
        padding: '12px 20px',
        display: 'flex',
        alignItems: 'center',
        '&:hover': {
          backgroundColor: '#E5F6F7',
          color: '#7e31ce',
        },
      }}
    >
      <HttpsIcon sx={{ marginRight: 1, color: '#7e31ce' }} />
      Change Password
    </MenuItem>

    <MenuItem
      onClick={handleDelete}
      sx={{
        fontSize: 16,
        fontWeight: '600',
        padding: '12px 20px',
        display: 'flex',
        alignItems: 'center',
        '&:hover': {
          backgroundColor: '#E5F6F7',
          color: '#d32f2f',
        },
      }}
    >
      <ExitToAppIcon sx={{ marginRight: 1, color: '#d32f2f' }} />
      Log Out
    </MenuItem>
  </Menu>

  {broken && rtl && (
    <IconButton
      sx={{
        margin: "0 10px 0 2px",
        padding: 2,
        transition: "transform 0.3s ease",
        '&:hover': {
          transform: 'scale(1.1)',
          backgroundColor: '#E5F6F7',
        },
      }}
      onClick={() => toggleSidebar()}
    >
      <MenuOutlinedIcon sx={{ fontSize: 28, color: "#13A9BD" }} />
    </IconButton>
  )}
</Box>


    </Box>
  );
};

export default Topbar;
