import React, { useState, useEffect, useCallback,useMemo } from "react";
import {
  Box,
  CardContent,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Avatar,
  Button,
  CircularProgress, TextField,FormControl, InputLabel, MenuItem, Select, Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Divider,
} from "@mui/material";
import { styled } from "@mui/system";
import { useNavigate } from "react-router-dom";
import ExcelJS from "exceljs";
import { saveAs } from "file-saver";
import { GlobalStyles } from "@mui/system";
import { useAuth } from "../../AuthProvider";

const Container = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  marginTop: theme.spacing(9),
  marginLeft: theme.spacing(3),
  marginRight: theme.spacing(3),
  borderRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette.common.white,
}));

const Title = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  color: theme.palette.primary.main,
  fontSize: "1.5rem",
  textAlign: "center",
  fontWeight: "bold",
}));

const SignedInSection = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(4),
}));

const SignedInTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
  fontSize: "1.25rem",
}));

const AbsentsList = React.memo(() => {
  const navigate = useNavigate();
  const [absentAttendance, setAttendanceAbsent] = useState([]);
  console.log("Absent Data:", absentAttendance);
  const [isLoading, setLoader] = useState(false); // State to track loading

   const [dateRange, setDateRange] = useState("today");
  
    const [openDialog, setOpenDialog] = useState(false); 
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [searchTerm, setSearchTerm] = useState("");
    const {organizationId}=useAuth()

  const getTodayDate = () => {
    const today = new Date();
    const yyyy = today.getFullYear();
    const mm = String(today.getMonth() + 1).padStart(2, "0");
    const dd = String(today.getDate()).padStart(2, "0");
    return `${yyyy}-${mm}-${dd}`;
  };

  const todayDate = getTodayDate();

  // useEffect(() => {
  //   const fetchAllAttendance = async () => {
   
  //     try {
  //       setLoader(true); // Start loader
  //       const response = await fetch(
  //         `https://login.hrbppayroll.com/hrbp/api/attendance/employees/no-checkin/${todayDate}`
  //       );
  //       if (!response.ok) {
  //         throw new Error("Network response was not ok");
  //       }
  //       const data = await response.json();
  //       setAttendanceAbsent(data);

  //     } catch (error) {
  //       console.error("Failed to fetch attendance", error);
  //     }finally {
  //       setLoader(false); // Hide loader
  //     }
  //   };

  //   fetchAllAttendance();
  // }, [todayDate]);

const handleDateRangeChange = (e) => {
    const selectedValue = e.target.value;
    setDateRange(selectedValue);
    
    if (selectedValue === "customDate") {
      setOpenDialog(true); // Open the dialog for custom date selection
    }

  };


  const handleGetButtonClick = () => {
    console.log("Start Date:", startDate, "End Date:", endDate);
    setOpenDialog(false);
    if (startDate && endDate) {
      setDateRange("customDate"); // Update state to trigger API call
      console.log("Date Range set to customDate");
    } else {
      console.error("Start Date or End Date is missing");
    }
  };
  

  const fetchAllAttendance = async () => {
    try {
      setLoader(true); // Start loader
      let endpoint = "";
  
      switch (dateRange) {
        case "today":
          endpoint = `https://login.hrbppayroll.com/hrbp/api/attendance/employees/no-checkin/${todayDate}/${organizationId}`;
          break;
        case "yesterday":
          endpoint = `https://login.hrbppayroll.com/hrbp/api/attendance/employees/no-checkin/yesterday/${todayDate}/${organizationId}`;
          break;
        case "lastWeek":
          endpoint = `https://login.hrbppayroll.com/hrbp/api/attendance/employees/no-checkin/lastWeek/${todayDate}/${organizationId}`;
          break;
       
          case "customDate":
            if (startDate && endDate) {
              endpoint = `https://login.hrbppayroll.com/hrbp/api/attendance/employees/no-checkin/customDate/${startDate}/${endDate}/${organizationId}`;
            } else {
              console.error("Custom date range is missing start or end date");
              return;
            }
            break;



        default:
          endpoint = `https://login.hrbppayroll.com/hrbp/api/attendance/employees/no-checkin/${todayDate}/${organizationId}`;
          break;
      }
  
      console.log("Endpoint:", endpoint);
  
      const response = await fetch(endpoint);
      const data = await response.json();

     // Handle transformed data only for 'lastWeek' case
    if (dateRange === "lastWeek") {
      const transformedData = data.flatMap((employee) =>
        employee.absentDates.map((date) => ({
          empId: employee.empId,
          firstName: employee.firstName,
          employeeId: employee.employeeId,
          emailId: employee.emailId,
          status: employee.status,
          absentDate: date, // Assign each absent date
        }))
      );
      setAttendanceAbsent(transformedData);
    } else if(dateRange === "customDate"){
      const transformedData = data.flatMap((employee) =>
      employee.absentDates.map((date) => ({
        empId: employee.empId,
        firstName: employee.firstName,
        employeeId: employee.employeeId,
        emailId: employee.emailId,
        status: employee.status,
        absentDate: date, // Assign each absent date
      }))
    );
    setAttendanceAbsent(transformedData);
    }else {
      // For all other cases, use the original data
      setAttendanceAbsent(data);
    }
      

          } catch (error) {
            console.error("Failed to fetch attendance", error);
          }finally {
            setLoader(false); // Hide loader
          }
        };
  
      
  useEffect(() => {
    // Fetch default attendance data on initial render
    if (!dateRange) {
      fetchAllAttendance();
    }
  }, []); // Run on initial render
    
      useEffect(() => {
        if (dateRange) {
          fetchAllAttendance();
        }
      }, [dateRange]);

      useEffect(() => {
        if (dateRange === "customDate" && startDate && endDate) {
          fetchAllAttendance();
        }
      }, [dateRange, startDate, endDate]);






  const handleClickOpen = useCallback((employee) => {
    navigate(`/attendance/${employee.empId}`);
  }, [navigate]);

  // const handleExportAbsents = useCallback(async () => {
  //   const workbook = new ExcelJS.Workbook();
  //   const worksheet = workbook.addWorksheet("Attendance Data");

  //   // Define the columns
  //   worksheet.columns = [
     
  //     { header: "Employee Id", key: "employeeId", width: 15 },
  //     { header: "Name", key: "name", width: 20 },
  //     { header: "Date", key: "date", width: 15 },
  //     { header: "Email Id", key: "emailId", width: 25 },
  //     { header: "Status", key: "status", width: 10 },
  //   ];

  //   // Add data with styling
  //   absentAttendance.forEach((employee) => {
  //     const row = worksheet.addRow({
     
  //       employeeId: employee.employeeId,
  //       name: `${employee.firstName} ${employee.lastName}`,
  //       date: todayDate,
  //       emailId: employee.emailId,
  //       status: "Absent",
  //     });

  //     // Style the status cell
  //     const statusCell = row.getCell(5);
  //     statusCell.fill = {
  //       type: "pattern",
  //       pattern: "solid",
  //       fgColor: { argb: "FFFF0000" }, // Red background for "Absent"
  //     };
  //     statusCell.font = { bold: true, color: { argb: "FF000000" } };
  //   });

  //   // Apply header styling
  //   worksheet.getRow(1).font = { bold: true, color: { argb: "FFFFFF" } };
  //   worksheet.getRow(1).fill = {
  //     type: "pattern",
  //     pattern: "solid",
  //     fgColor: { argb: "FF0000FF" }, // Blue background
  //   };

  //   // Save the workbook
  //   const buffer = await workbook.xlsx.writeBuffer();
  //   saveAs(new Blob([buffer]), "Employee_Absents_Attendance.xlsx");
  // }, [absentAttendance, todayDate]);

  const handleExportAbsents = async () => {
    // Create a new workbook and worksheet
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Attendance Data");

    // Define worksheet columns
    worksheet.columns = [
        { header: "Employee ID", key: "employeeId", width: 15 },
        { header: "Name", key: "name", width: 20 },
        { header: "Date", key: "date", width: 15 },
        { header: "Email ID", key: "emailId", width: 25 },
        { header: "Status", key: "status", width: 10 },
    ];

    // Add rows to the worksheet
    absentAttendance.forEach((employee) => {
        const row = worksheet.addRow({
            employeeId: employee.employeeId,
            name: `${employee.firstName} ${employee.lastName}`,
            date: todayDate,
            emailId: employee.emailId,
            status: "Absent",
        });

        // Style the status cell
        const statusCell = row.getCell(5); // Status column
        statusCell.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "FFFF0000" }, // Red background for "Absent"
        };
        statusCell.font = { bold: true, color: { argb: "FF000000" } }; // Bold black text
    });

    // Style each header cell
    const headerRow = worksheet.getRow(1);
    headerRow.eachCell((cell) => {
        cell.font = { bold: true, }; // Bold white text
        cell.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "87CEEB" }, // Blue background for header cells
        };
        cell.alignment = { horizontal: "center", vertical: "center" }; // Center align
    });

    // Write the Excel file and trigger download
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = "Employee_Absents_Attendance.xlsx";
    link.click();
};




  const filteredData = useMemo(() => {
    return absentAttendance.filter((employee) => {
      const firstName = employee.firstName?.toLowerCase() || "";
      const date = employee.date || "";
      const absentDate = employee.absentDate || "";
  
      // Ensure search term is in the same format as date fields
      const formattedSearchTerm = searchTerm.trim().toLowerCase();
  
      return (
        firstName.includes(formattedSearchTerm) ||
        date.includes(formattedSearchTerm) ||
        absentDate.includes(formattedSearchTerm)
      );
    });
  }, [searchTerm, absentAttendance]);
  

  return (
    <Container elevation={2}>
      <Title variant="h5">Employee Absent's List</Title>
      <SignedInSection>
        {/* <SignedInTitle variant="h6">
          Employees Absents Attendance(s)
        </SignedInTitle> */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: 2,
          }}
        >
          
            {/* Left side: FormControl and Search Field */}
        <Box sx={{ display: "flex", alignItems: "center" }}>
            <FormControl fullWidth variant="outlined" style={{ width: '280px' ,marginLeft:'15px'}}>
              <InputLabel>Date Range</InputLabel>
              <Select value={dateRange} onChange={handleDateRangeChange} label="Date Range">
                            <MenuItem value="today">Today</MenuItem>
                            <MenuItem value="yesterday">Yesterday</MenuItem>
                            <MenuItem value="lastWeek">Last Week</MenuItem>
                            <MenuItem value="customDate">Custom Date</MenuItem>
                            
              </Select>
            </FormControl>

            <TextField
            variant="outlined"
            placeholder="Search by Date or Name"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            style={{ width: "280px", marginLeft: "15px" }}
          />
         </Box>
          <Button
            variant="contained"
            sx={{
              backgroundColor: "#7e31ce",
              color: "#fff",
              "&:hover": { backgroundColor: "#7e31ce" },
              marginRight:'20px'
            }}
            onClick={handleExportAbsents}
          >
            Export
          </Button>
        </Box>

        <Box mt={1}>
          <CardContent>

          <GlobalStyles
          styles={{
            '.custom-sidebar-scrollbar': {
              scrollbarWidth: 'thin', // For Firefox
              scrollbarColor: 'grey #e0e0e0',
            },
            '.custom-sidebar-scrollbar::-webkit-scrollbar': {
              width: '6px', // For WebKit-based browsers
            },
            '.custom-sidebar-scrollbar::-webkit-scrollbar-track': {
              background: '#f1f1f1', // Track color
            },
            '.custom-sidebar-scrollbar::-webkit-scrollbar-thumb': {
              backgroundColor: 'grey', // Thumb color
              borderRadius: '10px',
            },
            '.custom-sidebar-scrollbar::-webkit-scrollbar-thumb:hover': {
              backgroundColor: '#555',
            },
          }}
          />

            <TableContainer component={Paper} style={{ maxHeight: '700px', overflowY: 'auto' }} className="custom-sidebar-scrollbar">
              <Table stickyHeader>
                <TableHead className="tableHeader">
                  <TableRow>
                  <TableCell
                      style={{
                        color: "white",
                        fontSize: "17px",
                        textAlign: "center",
                        fontWeight: "bold",
                        border: "1px solid black",
                         backgroundColor:'#529CAD'
                      }}
                    >
                      S.No
                    </TableCell>
                    <TableCell
                      style={{
                        color: "white",
                        fontSize: "17px",
                        textAlign: "center",
                        fontWeight: "bold",
                        border: "1px solid black",
                         backgroundColor:'#529CAD'
                      }}
                    >
                      Thumbnail
                    </TableCell>
                    <TableCell
                      style={{
                        color: "white",
                        fontSize: "17px",
                        textAlign: "center",
                        fontWeight: "bold",
                        border: "1px solid black",
                         backgroundColor:'#529CAD'
                      }}
                    >
                      Name
                    </TableCell>
                    <TableCell
                      style={{
                        color: "white",
                        fontSize: "17px",
                        textAlign: "center",
                        fontWeight: "bold",
                        border: "1px solid black",
                         backgroundColor:'#529CAD'
                      }}
                    >
                      Employee Id
                    </TableCell>
                    <TableCell
                      style={{
                        color: "white",
                        fontSize: "17px",
                        textAlign: "center",
                        fontWeight: "bold",
                        border: "1px solid black",
                         backgroundColor:'#529CAD'
                      }}
                    >
                      Date
                    </TableCell>
                    <TableCell
                      style={{
                        color: "white",
                        fontSize: "17px",
                        textAlign: "center",
                        fontWeight: "bold",
                        border: "1px solid black",
                         backgroundColor:'#529CAD'
                      }}
                    >
                      Email Id
                    </TableCell>
                    <TableCell
                      style={{
                        color: "white",
                        fontSize: "17px",
                        textAlign: "center",
                        fontWeight: "bold",
                        border: "1px solid black",
                         backgroundColor:'#529CAD'
                      }}
                    >
                      Status
                    </TableCell>
                    <TableCell
                      style={{
                        color: "white",
                        fontSize: "17px",
                        textAlign: "center",
                        fontWeight: "bold",
                        border: "1px solid black",
                         backgroundColor:'#529CAD'
                      }}
                    >
                      Action
                    </TableCell>
                  </TableRow>
                </TableHead>
                     <TableBody>
                     {isLoading ? (
                                                <TableRow>
                                                  <TableCell colSpan={8} style={{ textAlign: "center", padding: "20px",fontSize:'15px' }}>
                                                    Loading Data...
                                                  </TableCell>
                                                </TableRow>
                                              ) : filteredData.length === 0 ? (
                                                <TableRow>
                                                  <TableCell colSpan={8} style={{ textAlign: "center", padding: "20px" }}>
                                                    No data available
                                                  </TableCell>
                                                </TableRow>
                                              ) : (
                    filteredData.map((employee,index) => (
                      <TableRow key={employee.id}>
                        <TableCell
                          className="tableCell"
                          style={{
                            color: "black",
                            fontSize: "17px",
                            textAlign: "center",
                            border: "1px solid black",
                          }}
                        >
                          {index+1}
                        </TableCell>
                        <TableCell className="tableCell" style={{ textAlign: 'center',border: "1px solid black", }}>
                          <Avatar
                            alt={employee.firstName}
                            style={{ marginLeft: "35%" }}
                            src={`data:image/jpeg;base64,${employee.profilePic}`}
                          />
                        </TableCell>
                        <TableCell
                          className="tableCell"
                          style={{
                            color: "black",
                            fontSize: "17px",
                            textAlign: "center",
                            border: "1px solid black",
                          }}
                        >
                          {employee.firstName} 
                          {/* {employee.lastName} */}
                        </TableCell>
                        <TableCell
                          className="tableCell"
                          style={{
                            color: "black",
                            fontSize: "17px",
                            textAlign: "center",
                            border: "1px solid black",
                          }}
                        >
                          {employee.employeeId}
                        </TableCell>
                        <TableCell
                          className="tableCell"
                          style={{
                            color: "black",
                            fontSize: "17px",
                            textAlign: "center",
                            border: "1px solid black",
                          }}
                        >
                          {dateRange === "lastWeek" || dateRange === "customDate" ? employee.absentDate : employee.date}
                        </TableCell>
                        <TableCell
                          className="tableCell"
                          style={{
                            color: "black",
                            fontSize: "17px",
                            textAlign: "center",
                            border: "1px solid black",
                          }}
                        >
                          {employee.emailId}
                        </TableCell>
                        <TableCell
                          className="tableCell"
                          style={{
                            color: "red",
                            fontSize: "17px",
                            textAlign: "center",
                            fontWeight: "bold",
                            border: "1px solid black",
                          }}
                        >
                          {/* Absent */}
                          {employee.status}
                        </TableCell>
                        <TableCell
                          className="tableCell"
                          style={{
                            color: "red",
                            fontSize: "17px",
                            textAlign: "center",
                            border: "1px solid black",
                          }}
                        >
                          <Box sx={{ display: "flex", justifyContent: "space-around" }}>
                            <Button
                              variant="contained"
                              sx={{
                                backgroundColor: "#7e31ce",
                                color: "#fff",
                                "&:hover": { backgroundColor: "#7e31ce" },
                                textAlign: "center",
                              }}
                              onClick={() => handleClickOpen(employee)}
                            >
                              Attendance
                            </Button>
                          </Box>
                        </TableCell>
                      </TableRow>
                    ))
                    )}
                  </TableBody>
               
              </Table>
            </TableContainer>
          </CardContent>
        </Box>
      </SignedInSection>

 {/* Dialo for start date and end date selection  */}

 <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
  <DialogTitle sx={{fontWeight:'bold',fontSize:'15px'}}>Select Custom Date Range</DialogTitle>
  <DialogContent>
    <div style={{ display: "flex", gap: "16px" ,marginTop:'10px'}}>
      <TextField
        label="Start Date"
        type="date"
        InputLabelProps={{ shrink: true }}
        value={startDate}
        onChange={(e) => setStartDate(e.target.value)}
        fullWidth
      />
      <TextField
        label="End Date"
        type="date"
        InputLabelProps={{ shrink: true }}
        value={endDate}
        onChange={(e) => setEndDate(e.target.value)}
        fullWidth
      />
    </div>
  </DialogContent>
  <DialogActions>
    <Button onClick={() => setOpenDialog(false)} variant="outlined" sx={{width:'5px'}}>Cancel</Button>
    <Button
      onClick={() => {
        if (startDate && endDate) {
          handleGetButtonClick();
        } else {
          alert("Please select both Start Date and End Date.");
        }
      }}
    sx={{backgroundColor: "#7e31ce",color: "#fff", "&:hover": { backgroundColor: "#7e31ce" }}}
      variant="outlined"
    >
      Get
    </Button>
  </DialogActions>
</Dialog>



    </Container>
  );
});

export default AbsentsList;
