import React, { useState, useEffect } from "react";
import ExcelJS from "exceljs";
import { saveAs } from "file-saver";
import {
  Box,
  Card,
  CardContent,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Avatar,
  CircularProgress,
  TablePagination,
  IconButton,
} from "@mui/material";
import { styled } from "@mui/system";
import { useNavigate } from "react-router-dom";
import DeleteIcon from '@mui/icons-material/Delete';
import ScreenShareOutlinedIcon from "@mui/icons-material/ScreenShareOutlined";
import Swal from 'sweetalert2'; // Import Swal
import { GlobalStyles } from "@mui/system";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useAuth } from "../../AuthProvider";

const Title = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  color: theme.palette.primary.main,
  fontSize: "1.5rem",
  textAlign: "center",
  fontWeight: "bold",
}));

function Employee() {
  const [employees, setEmployees] = useState([]);
  const [loader, setLoader] = useState(true);
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const {organizationId}=useAuth()

  useEffect(() => {
    fetchEmployees();
  }, []);

  // Fetch employees
  const fetchEmployees = async () => {
    try {
      setLoader(true);
      const response = await fetch(`https://login.hrbppayroll.com/hrbp/api/employee/getAll/${organizationId}`);
      const data = await response.json();
      const employeesWithImages = data.map((employee) => ({
        ...employee,
        profilePic: `data:image/jpeg;base64,${employee.profilePic}`,
      }));
      setEmployees(employeesWithImages);
    } catch (error) {
      console.error("Error fetching employees:", error);
    } finally {
      setLoader(false);
    }
  };

  // Handle delete with swal confirmation
  const handleDelete = async (employeeId, employeeName) => {
    // Show confirmation dialog with swal
    Swal.fire({
      title: `Are you sure you want to delete?`,
      text: `Employee Name: ${employeeName}`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const response = await fetch(`https://login.hrbppayroll.com/hrbp/api/employee/delete/${employeeId}`, {
            method: 'DELETE',
          });
          if (response.ok) {
            // Swal.fire("Employee Deleted!", `${employeeName} has been deleted.`, "success");
            toast.success(`${employeeName} has been deleted successfully!`, {
              position:"top-right",
              autoClose: 1000,
            });
            // Remove the employee from the state immutably
            setEmployees((prevEmployees) =>
              prevEmployees.filter((employee) => employee.employeeId !== employeeId)
            );
          } else {
            console.error("Error deleting employee:", response.statusText);
            // Swal.fire("Error!", "An error occurred while deleting.", "error");
            toast.error("There was an issue while deleting", {
              position: "top-right",
              autoClose: 1000,
            });
          }
        } catch (error) {
          console.error("Error deleting employee:", error);
          // Swal.fire("Error!", "An error occurred while deleting.", "error");
          toast.error("There was an issue while deleting", {
            position: "top-right",
            autoClose: 1000,
          });
        }
      }
    });
  };

  const handleClickOpen = (employee) => {
    navigate(`/employeeDetails/${employee.empId}`);
  };

  const handleNavigateToOtherComponent = () => {
    navigate("/employeeRegForm");
  };


  //EXCEL EXPORT CODE


  // const handleExport = async () => {
  //   const workbook = new ExcelJS.Workbook();
  //   const worksheet = workbook.addWorksheet("Employees Data");
  
  //   worksheet.columns = [
  //     { header: "Employee ID", key: "employeeId", width: 15 },
  //     { header: "Employee Name", key: "employeeName", width: 15 },
  //     { header: "Mobile No", key: "mobileNo", width: 15 },
  //     { header: "E-mail", key: "email", width: 20 },
  //   ];
  
  //   employees.forEach((employee) => {
  //     worksheet.addRow({
  //       employeeId: employee.employeeId,
  //       employeeName: `${employee.firstName}  ${employee.lastName}`,
  //       mobileNo: employee.contactNumber1,
  //       email: employee.emailId,
  //     });
  //   });
  
  //   // Set header font to bold and add grey background
  //   const headerRow = worksheet.getRow(1);
  //   headerRow.font = { bold: true };
  //   headerRow.fill = {
  //     type: "pattern",
  //     pattern: "solid",
  //     fgColor: { argb: "FF808180" }, // Grey color
  //   };
  
  //   const buffer = await workbook.xlsx.writeBuffer();
  //   saveAs(new Blob([buffer]), "Employees_List.xlsx");
  // };

  const handleExport = async () => {
    // Create a new workbook and worksheet
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Employees Data");

    // Define worksheet columns
    worksheet.columns = [
        { header: "Employee ID", key: "employeeId", width: 15 },
        { header: "Employee Name", key: "employeeName", width: 25 },
        { header: "Mobile No", key: "mobileNo", width: 15 },
        { header: "E-mail", key: "email", width: 25 },
    ];

    // Add rows to the worksheet
    employees.forEach((employee) => {
        worksheet.addRow({
            employeeId: employee.employeeId,
            employeeName: `${employee.firstName} ${employee.lastName}`,
            mobileNo: employee.contactNumber1,
            email: employee.emailId,
        });
    });

    // Style each header cell
    const headerRow = worksheet.getRow(1);
    headerRow.eachCell((cell) => {
        cell.font = { bold: true }; // Bold font
        cell.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "87CEEB" }, // Grey background for header cells
        };
        cell.alignment = { horizontal: "center", vertical: "center" }; // Center align
    });

    // Write the Excel file and trigger download
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = "Employees_List.xlsx";
    link.click();
};



  
  
  

  // const handleChangePage = (event, newPage) => {
  //   setPage(newPage);
  // };

  // const handleChangeRowsPerPage = (event) => {
  //   setRowsPerPage(parseInt(event.target.value, 10));
  //   setPage(0);
  // };

  // const paginatedEmployees = employees.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  return (
   
    <Box>
       
      <Box mt={11} marginLeft={2} marginRight={2}>
      
        <Card
          sx={{
            backgroundColor: "white",
            boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.2)",width:'100%'
          }}
         
        >
         

          <Title variant="h5" sx={{ marginTop: 3 }}>Employees Management</Title>
          <CardContent>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              mb={2}
            >
              <Typography variant="h5" sx={{ fontSize: 20, marginTop: 5 }}>
                Employees Management:
              </Typography>
              <Box>
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: "#7e31ce",
                    color: "#fff",
                    "&:hover": { backgroundColor: "#13A9BD" },
                    fontWeight: "bold",
                    marginRight: 2,
                    marginBottom: 2,
                  }}
                  onClick={handleNavigateToOtherComponent}
                >
                  Add New Employee
                </Button>

                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: "#7e31ce",
                    color: "#fff",
                    "&:hover": { backgroundColor: "#7e31ce" },
                    
                    marginBottom: 2,
                  }}
                  onClick={handleExport}
                >
                  <ScreenShareOutlinedIcon sx={{ marginRight: 1 }} />{" "}
                  {/* Insert custom Excel icon */}
                  Export
                </Button>

              </Box>
            </Box>


            <GlobalStyles
          styles={{
            '.custom-sidebar-scrollbar': {
              scrollbarWidth: 'thin', // For Firefox
              scrollbarColor: 'grey #e0e0e0',
            },
            '.custom-sidebar-scrollbar::-webkit-scrollbar': {
              width: '6px', // For WebKit-based browsers
            },
            '.custom-sidebar-scrollbar::-webkit-scrollbar-track': {
              background: '#f1f1f1', // Track color
            },
            '.custom-sidebar-scrollbar::-webkit-scrollbar-thumb': {
              backgroundColor: 'grey', // Thumb color
              borderRadius: '10px',
            },
            '.custom-sidebar-scrollbar::-webkit-scrollbar-thumb:hover': {
              backgroundColor: '#555',
            },
          }}
        />
            <TableContainer component={Paper} style={{ maxHeight: '500px', overflowY: 'auto' }} className="custom-sidebar-scrollbar">
              <Table stickyHeader>
                <TableHead className="tableHeader" >
                  <TableRow>
                  <TableCell style={{ color: "white", fontSize: "17px", textAlign: "center", fontWeight: "bold", border: "1px solid black", backgroundColor:'#529CAD'}}>S.No</TableCell>
                    <TableCell style={{ color: "white", fontSize: "17px", textAlign: "center", fontWeight: "bold", border: "1px solid black" , backgroundColor:'#529CAD'}}>Thumbnail</TableCell>
                    <TableCell style={{ color: "white", fontSize: "17px", textAlign: "center", fontWeight: "bold", border: "1px solid black", backgroundColor:'#529CAD' }}>Employee Id</TableCell>
                    <TableCell style={{ color: "white", fontSize: "17px", textAlign: "center", fontWeight: "bold", border: "1px solid black" , backgroundColor:'#529CAD'}}>Employee Name</TableCell>
                    <TableCell style={{ color: "white", fontSize: "17px", textAlign: "center", fontWeight: "bold", border: "1px solid black" , backgroundColor:'#529CAD'}}>Mobile</TableCell>
                    <TableCell style={{ color: "white", fontSize: "17px", textAlign: "center", fontWeight: "bold", border: "1px solid black" , backgroundColor:'#529CAD'}}>E-mail</TableCell>
                    <TableCell style={{ color: "white", fontSize: "17px", textAlign: "center", fontWeight: "bold", border: "1px solid black" , backgroundColor:'#529CAD'}}>Action</TableCell>
                    <TableCell style={{ color: "white", fontSize: "17px", textAlign: "center", fontWeight: "bold", border: "1px solid black" , backgroundColor:'#529CAD'}}>Delete</TableCell>
                  </TableRow>
                </TableHead>
                  <TableBody>
                  {loader ? (
                                                <TableRow>
                                                  <TableCell colSpan={8} style={{ textAlign: "center", padding: "20px",fontSize:'15px' }}>
                                                    Loading Data...
                                                  </TableCell>
                                                </TableRow>
                                              ) : employees.length === 0 ? (
                                                <TableRow>
                                                  <TableCell colSpan={8} style={{ textAlign: "center", padding: "20px" }}>
                                                    No data available
                                                  </TableCell>
                                                </TableRow>
                                              ) : (
                      employees.map((employee,index) => (
                      <TableRow key={employee.id}>
                         <TableCell className="tableCell" style={{ color: "black", fontSize: "15px", textAlign: "center", border: "1px solid black" }}>
                          {index+1}
                        </TableCell>
                        <TableCell className="tableCell" style={{ border: "1px solid black" }}>
                          <Avatar alt={employee.firstName} style={{ marginLeft: 40 }} src={employee.profilePic} />
                        </TableCell>
                        <TableCell className="tableCell" style={{ color: "black", fontSize: "15px", textAlign: "center", border: "1px solid black" }}>
                          {employee.employeeId}
                        </TableCell>
                        <TableCell className="tableCell" style={{ color: "black", fontSize: "15px", textAlign: "center", border: "1px solid black" }}>
                          {employee.firstName} {employee.lastName}
                        </TableCell>
                        <TableCell className="tableCell" style={{ color: "black", fontSize: "15px", textAlign: "center", border: "1px solid black" }}>
                          {employee.contactNumber1}
                        </TableCell>
                        <TableCell className="tableCell" style={{ color: "black", fontSize: "15px", textAlign: "center", border: "1px solid black" }}>
                          {employee.emailId}
                        </TableCell>
                        <TableCell className="tableCell" style={{ color: "black", fontSize: "12px", textAlign: "center", border: "1px solid black" }}>
                          <Button
                            variant="contained"
                            sx={{ backgroundColor: "#7e31ce", color: "#fff", "&:hover": { backgroundColor: "#13A9BD" }, textAlign: "center" }}
                            onClick={() => handleClickOpen(employee)}
                          >
                            View
                          </Button>
                        </TableCell>
                        <TableCell className="tableCell" style={{ border: "1px solid black", textAlign: "center" }}>
                          <IconButton
                            aria-label="delete"
                            onClick={() => handleDelete(employee.employeeId, `${employee.firstName} ${employee.lastName}`)} // Pass employee name
                            sx={{ color: "red" }}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))
                    )}
                  
                  </TableBody>
               
              </Table>
            </TableContainer>

            {/* Pagination */}
            {/* <TablePagination
              component="div"
              count={employees.length}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            /> */}
          </CardContent>
         
        </Card>
        
      </Box>
      <ToastContainer/>
    </Box>
    
  );
}

export default Employee;
