import React, { createContext, useContext, useState, useEffect } from 'react';

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  // Initialize states with data from localStorage, if available
  const [companyEmailId, setCompanyEmailId] = useState(() => {
    return localStorage.getItem('companyEmailId') || null;
  });
  const [organizationId, setOrganizationId] = useState(() => {
    return localStorage.getItem('organizationId') || null;
  });
  const [companyName, setCompanyName] = useState(() => {
    return localStorage.getItem('companyName') || null;
  });

  // Save companyEmailId to localStorage whenever it changes
  useEffect(() => {
    if (companyEmailId !== null) {
      localStorage.setItem('companyEmailId', companyEmailId);
    }
  }, [companyEmailId]);

  // Save organizationId to localStorage whenever it changes
  useEffect(() => {
    if (organizationId !== null) {
      localStorage.setItem('organizationId', organizationId);
    }
  }, [organizationId]);

  useEffect(() => {
    if (companyName !== null) {
      localStorage.setItem('companyName', companyName);
    }
  }, [companyName]);

  return (
    <AuthContext.Provider value={{ companyEmailId, setCompanyEmailId, organizationId, setOrganizationId ,companyName,setCompanyName}}>
      {children}
    </AuthContext.Provider>
  );
};
