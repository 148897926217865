import React, { useState, useCallback, useMemo, useEffect } from "react";
import {
  Box,
  Card,
  CardContent,
  Typography,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  IconButton,
  CircularProgress,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import DeleteIcon from "@mui/icons-material/Delete";
import swal from "sweetalert2";
import { format, parseISO } from "date-fns";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useAuth } from "../../AuthProvider";

function HolidayCalendar() {
  const [open, setOpen] = useState(false);
  const [holidayName, setHolidayName] = useState("");
  const [holidayDate, setHolidayDate] = useState(null);
  const [holidays, setHolidays] = useState([]);
  const [loading, setLoading] = useState(true);
  const {organizationId}=useAuth()

  const fetchHolidays = async () => {
    setLoading(true);
    try {
      const response = await fetch(`https://login.hrbppayroll.com/hrbp/api/holiday/all/${organizationId}`);
      if (!response.ok) throw new Error("Failed to fetch holidays");

      const data = await response.json();
      setHolidays(data);
    } catch (error) {
      console.error("Error fetching holidays:", error);
      // swal.fire("Error", "Failed to load holidays", "error");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchHolidays();
  }, []);

  const handleAddHoliday = async () => {

    if (!holidayName.trim()) {
      toast.error("Please enter a holiday name.", {
          position: "top-right",
          autoClose: 1000,
      });
      return;
  }
    if (!holidayDate) {
        toast.error("Please select a date before adding a holiday.", {
            position: "top-right",
            autoClose: 1000,
        });
        return;
    }

    setLoading(true); // Start loading

    const formattedDate = format(holidayDate, "yyyy-MM-dd");
    const newHoliday = { name: holidayName, date: formattedDate };

    try {
        const response = await fetch(`https://login.hrbppayroll.com/hrbp/api/holiday/save/${holidayName}/${formattedDate}/${organizationId}`, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(newHoliday),
        });

        if (!response.ok) throw new Error("Failed to add holiday");

        const updatedHolidaysResponse = await fetch(`https://login.hrbppayroll.com/hrbp/api/holiday/all/${organizationId}`);
        const updatedHolidays = await updatedHolidaysResponse.json();
        updatedHolidays.sort((a, b) => new Date(a.holidayDate) - new Date(b.holidayDate));

        setHolidays(updatedHolidays);

        toast.success(`${holidayName} added successfully!`, {
            position: "top-right",
            autoClose: 1000,
        });
    } catch (error) {
        console.error("Error adding holiday:", error);

        toast.error("There was an issue adding the holiday", {
            position: "top-right",
            autoClose: 1000,
        });
    } finally {
        setLoading(false); // Stop loading
        setHolidayName("");
        setHolidayDate(null);
        setOpen(false);
    }
};

  

  const handleRemoveHoliday = useCallback(
    async (holidayId, holidayName) => {
      swal
        .fire({
          title: `Are you sure you want to remove ${holidayName}?`,
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, remove it!",
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            try {
              const response = await fetch(`https://login.hrbppayroll.com/hrbp/api/holiday/${holidayId}`, {
                method: "DELETE",
              });
              if (response.ok) {
                const updatedHolidays = holidays.filter(holiday => holiday.holidayId !== holidayId);
                setHolidays(updatedHolidays);
                // swal.fire("Deleted!", `${holidayName} has been removed.`, "success");
                toast.success(`${holidayName} has been removed!`, {
                  position:"top-right",
                  autoClose: 1000,
                });
              } else {
                // swal.fire("Error!", "An error occurred while deleting the holiday.", "error");
                toast.error("There was an issue while removing the holiday", {
                  position: "top-right",
                  autoClose: 1000,
                });
              }
            } catch (error) {
              // console.error("Error deleting holiday:", error);
              // swal.fire("Error!", "An error occurred while deleting the holiday.", "error");

              toast.error("There was an issue while removing the holiday", {
                position: "top-right",
                autoClose: 1000,
              });
            }
          }
        });
    },
    [holidays]
  );

  // Define an array of month names in order
  const monthNames = [
    "January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
  ];

  // Group holidays by month and year
  const groupedHolidays = useMemo(() => {
    return holidays.reduce((acc, holiday) => {
      const holidayMonth = format(parseISO(holiday.holidayDate), "MMMM yyyy");
      if (!acc[holidayMonth]) {
        acc[holidayMonth] = [];
      }
      acc[holidayMonth].push(holiday);
      return acc;
    }, {});
  }, [holidays]);

  // Sort the grouped holidays based on month order
  const sortedMonths = Object.keys(groupedHolidays).sort((a, b) => {
    const monthA = a.split(" ")[0];
    const monthB = b.split(" ")[0];
    return monthNames.indexOf(monthA) - monthNames.indexOf(monthB);
  });

  return (
    <Box>
      <Box mt={11} marginLeft={2} marginRight={2}>
        <Card sx={{ backgroundColor: "white", boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.2)" }}>
          <CardContent>
            <Box display="flex" justifyContent="start" alignItems="center" mb={2}>
              <Typography variant="h4" sx={{ fontSize: 20, marginBottom: 10, margin: 2 }}>
                <strong>Holidays</strong>
              </Typography>
            </Box>
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <Typography variant="h5" sx={{ fontSize: 20, marginLeft: 9, flexGrow: 1 }}>
                <strong>Holidays List :</strong>
              </Typography>

              <Button
                variant="contained"
                onClick={() => setOpen(true)}
                sx={{ marginRight: 9, backgroundColor: "#7e31ce",fontWeight:'bold', color: "#fff", "&:hover": { backgroundColor: "#7e31ce" } }}
              >
                + Add New Holiday
              </Button>
            </Box>

          {loading ? (
  <Box display="flex" flexDirection="column" alignItems="center" marginTop={2}>
    <CircularProgress />
    <Typography variant="body1" sx={{ textAlign: "center", marginTop: 2 }}>
      Loading holidays list......
    </Typography>
  </Box>
) : (
  sortedMonths.map((month) => (
    <Box key={month}>
      <Typography variant="h6" sx={{
        marginTop: 3,
        marginLeft: 9,
        fontWeight: "bold",
        color: "#FF4081",
        textTransform: "uppercase",
        fontSize: "1.2rem",
      }}>
        {month}
      </Typography>
      {groupedHolidays[month]
        .sort((a, b) => new Date(a.holidayDate) - new Date(b.holidayDate))
        .map((holiday) => (
          <Card
            key={holiday.holidayId}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginTop: 1,
              padding: 1,
              width: "90%",
              marginLeft: 9,
              backgroundColor: "#BCE3E9",
              borderRadius: "10px", // Rounded corners
              boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)", // Card shadow
              transition: "all 0.3s ease-in-out", // Smooth transition on hover
            }}
          >
            <CardContent sx={{ flex: 1 }}>
              <Typography variant="h4" style={{ color: "#864CE4", fontWeight: "bold" }}>
                {holiday.holidayName}
              </Typography>
              <Typography variant="h6" style={{ fontWeight: "bold" }} color="textSecondary">
                {format(parseISO(holiday.holidayDate), "dd MMMM yyyy")}
              </Typography>
            </CardContent>

            <IconButton
              aria-label="delete"
              onClick={() => handleRemoveHoliday(holiday.holidayId, holiday.holidayName)}
              color="error"
              sx={{
                marginRight: "20px",
                backgroundColor: "#FF1744", // Red background color
                borderRadius: "50%", // Circle shape
                padding: "5px", // Space inside the circle for the icon
                '&:hover': {
                  backgroundColor: "#D50000", // Darker shade on hover
                  transform: "scale(1.1)", // Slightly enlarge the icon on hover
                },
              }}
            >
              <DeleteIcon sx={{ color: "white" }} /> {/* White color for the icon */}
            </IconButton>
          </Card>
        ))}
    </Box>
  ))
)}


          </CardContent>
        </Card>
      </Box>

      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        fullWidth
        maxWidth="md"
        sx={{ "& .MuiDialog-paper": { width: "700px", maxWidth: "100%", height: "300px" } }}
      >
        <DialogTitle sx={{ fontWeight: "bold", fontSize: "20px" }}>Add Holiday</DialogTitle>
        <DialogContent>
          <Box display="flex" flexDirection="column" marginTop={1}>
            <TextField
              label="Holiday Name"
              variant="outlined"
              value={holidayName}
              onChange={(e) => setHolidayName(e.target.value)}
              fullWidth
              margin="normal"
            />
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                label="Holiday Date"
                value={holidayDate}
                onChange={(newValue) => setHolidayDate(newValue)}
                renderInput={(params) => <TextField {...params} fullWidth />}
              />
            </LocalizationProvider>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button 
            onClick={() => setOpen(false)}
            sx={{
              backgroundColor: "#7e31ce",
              color: "#fff",
              "&:hover": { backgroundColor: "#7e31ce" },
            }}>
            Cancel
          </Button> 

          {/* <Button onClick={handleAddHoliday} disabled={!holidayName || !holidayDate}>
            Add Holiday
          </Button> */}
         <Button
        onClick={handleAddHoliday}
        disabled={loading} // Disable button while loading
        sx={{
            backgroundColor: loading ? "#ccc" : "#7e31ce",
            color: "#fff",
            "&:hover": { backgroundColor: loading ? "#ccc" : "#13A9BD" },
        }}
    >
        {loading ? "Adding..." : "Add Holiday"}
    </Button>
        </DialogActions>
      </Dialog>
      <ToastContainer/>
    </Box>
  );
}

export default HolidayCalendar;
