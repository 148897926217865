import React, { useState } from "react";
import {
  Button,
  TextField,
  Typography,
  Container,
  Link,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";
import { useAuth } from "../../AuthProvider";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    backgroundImage:
      'url("https://static.vecteezy.com/system/resources/previews/011/845/213/non_2x/aesthetic-abstract-curve-striped-gradient-green-blank-frame-wallpaper-illustration-perfect-for-wallpaper-backdrop-postcard-background-banner-vector.jpg")',
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    padding: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(1),
      justifyContent: "flex-start", // Adjust alignment for smaller screens
    },
  },
  logoContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginBottom: theme.spacing(2),
  },
  logo: {
    width: "185px", // Adjust the width of the logo as needed
    height: "160px",
    marginBottom: theme.spacing(1),
    [theme.breakpoints.down("sm")]: {
      width: "150px",
      height: "130px",
    },
  },
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: theme.spacing(4),
    borderRadius: "10px",
    backgroundColor: "rgba(0, 0, 0, 0.7)",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(3),
      width: "90%", // Take most of the width on small screens
    },
  },
  form: {
    width: "100%",
    marginTop: theme.spacing(4),
  },
  textField: {
    marginBottom: theme.spacing(2),
    "& .MuiFilledInput-root": {
      backgroundColor: "rgba(255, 255, 255, 0.8)",
      borderRadius: "5px",
    },
    "& .MuiInputBase-input": {
      color: "black",
    },
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: "#2ED0A5",
    color: "#FFFFFF",
    [theme.breakpoints.down("xs")]: {
      margin: theme.spacing(2, 0, 1),
    },
  },
  link: {
    marginTop: theme.spacing(2),
    color: "#FFFFFF",
    textAlign: "center",
    display: "block",
  },
  // dialog: {
  //   minWidth: "400px", // Set a larger width for the dialog
  //   backgroundColor: "#f9f9f9", // Light background color
  //   borderRadius: "10px", // Rounded corners for the dialog
  // },
  // emailText: {
  //   margin: theme.spacing(2, 0),
  //   fontSize: "16px", // Adjust the font size
  //   fontWeight: "500",
  //   color: "#333", // Text color for email
  //   backgroundColor: "rgba(255, 255, 255, 0.9)", // Light background
  //   borderRadius: "5px", // Rounded corners for the text
  //   padding: "10px",
  // },
  button: {
    backgroundColor: "#2ED0A5", // Submit button background color
    width:'20%',
    color: "white",
    "&:hover": {
      backgroundColor: "#1c9d82", // Button hover effect
    },
  },
  cancelButton: {
    backgroundColor: "#f44336", // Cancel button background color
    width:'20%',
    color: "white",
    "&:hover": {
      backgroundColor: "#d32f2f", // Cancel button hover effect
    },
  },
  dialogTitle: {
    textAlign: "center",
    color: "#801CA7",
    fontWeight: "bold",
  },
}));

const SignIn = () => {
  const classes = useStyles();
  const navigate = useNavigate();

  const [values, setValues] = useState({
    email: "",
    password: "",
  });
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [openDialog, setOpenDialog] = useState(false); // For opening forgot password dialog
  const [emailForPasswordReset, setEmailForPasswordReset] = useState("");
  const { setCompanyEmailId, setOrganizationId } = useAuth();

  const validate = () => {
    const newErrors = {};
    let isValid = true;

    if (!values.email) {
      newErrors.email = "Email is required";
      isValid = false;
    }

    if (!values.password) {
      newErrors.password = "Password is required";
      isValid = false;
    }

    setError(newErrors);
    return isValid;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!validate()) return;

    setLoading(true);
    setError(""); // Clear previous errors

    try {
      const formData = new FormData();
      Object.keys(values).forEach((key) => {
        formData.append(key, values[key]);
      });

      const response = await fetch(`https://login.hrbppayroll.com/hrbp/api/v1/auth/authenticate`, {
        method: "POST",
        body: formData,
      });

      const responseData = await response.json(); // Parse JSON response

      if (response.ok) {
        localStorage.setItem("token", responseData.access_token);

        // Assuming you get accessToken in response
        const accessToken = responseData.access_token;

        // Decode access token to extract role
        const decodedToken = JSON.parse(atob(accessToken.split(".")[1])); // Decode JWT

        const userRole = decodedToken.role; // Extract role from token
        const companyEmailId = decodedToken.email; // Extract email
        const organizationId = decodedToken.oId; // Extract organization ID

        // Store company email and organization ID in localStorage
        localStorage.setItem("companyEmailId", companyEmailId);
        localStorage.setItem("organizationId", organizationId);
        setOrganizationId(organizationId)
        localStorage.setItem("role", userRole); // Store role for future use

        // Conditional navigation based on role
        if (userRole === "ORGANIZATION") {
          navigate("/dashboard"); // Navigate to Organization Dashboard
        } else if (userRole === "ADMIN") {
          navigate("/adminDashboard"); // Navigate to Admin Dashboard
        } else {
          navigate("/dashboard"); // Default route for other roles
        }
      } else {
        // Handle error response
        toast.error(responseData.message, {
          position: "top-right",
          autoClose: 2000,
        });
      }
    } catch (error) {
      console.error("Caught error:", error); // Log the error for debugging
      toast.error("Check server connection", {
        position: "top-right",
        autoClose: 2000,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setValues((prev) => ({ ...prev, [name]: value }));
  };

  const handleCreateAccount = () => {
    navigate("/signup"); // Navigate to Signup page
  };

  const handleForgotPasswordClick = () => {
    setOpenDialog(true); // Open the forgot password dialog
  };

  const handlePasswordResetSubmit = async () => {
    if (!emailForPasswordReset) {
      toast.error("Please enter your email", { position: "top-right", autoClose: 2000 });
      return;
    }
  
    setLoading(true); // Start loading
  
    try {
      const response = await fetch(`https://login.hrbppayroll.com/hrbp/api/users/forgot-password/organisation/${emailForPasswordReset}`, {
        method: "PUT",
      });
  
      const responseText = await response.text(); // Get the response as plain text
  
     
  
      if (response.ok && responseText === "success") {
        toast.success("Reset password sent to your email", { position: "top-right", autoClose: 1000 });
        setOpenDialog(false); // Close the dialog
      } else {
        // If response is not the expected email, show an error
        toast.error( responseText, { position: "top-right", autoClose: 1000 });
      }
    } catch (error) {
      console.error("Error resetting password:", error);
      toast.error("Something went wrong. Please try again", { position: "top-right", autoClose: 1000 });
    } finally {
      setLoading(false); // Stop loading
    }
  };
  
  
  
  

  return (
    <div className={classes.root}>
      <Box className={classes.logoContainer}>
        <img src="/assets/hrbplogo.png" alt="Company Logo" className={classes.logo} />
        <Typography variant="h5" style={{ color: "#801CA7", fontWeight: "bold" }}>
          HRBP PAYROLL
        </Typography>
      </Box>
      <Container component="main" maxWidth="xs" className={classes.container}>
        <Typography variant="h4" align="center" style={{ color: "white" }}>
          Sign In
        </Typography>
        <form onSubmit={handleSubmit} className={classes.form}>
          <TextField
            className={classes.textField}
            variant="filled"
            fullWidth
            id="email"
            label="Email"
            type="email"
            name="email"
            autoComplete="email"
            autoFocus
            value={values.email}
            onChange={handleChange}
            error={!!error.email}
            helperText={error.email}
          />
          <TextField
            className={classes.textField}
            variant="filled"
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            value={values.password}
            onChange={handleChange}
            error={!!error.password}
            helperText={error.password}
          />
          <Button type="submit" fullWidth variant="contained" className={classes.submit} disabled={loading}>
            {loading ? "Log In..." : "Log In"}
          </Button>
          <Typography align="center" style={{ color: "white" }}>
            Not registered yet?
            <Link onClick={handleCreateAccount} style={{ color: "#ffff", fontWeight: "bold", cursor: 'pointer' }}>
              Create an account
            </Link>
          </Typography>

          {/* Forgot password link */}
          <Typography align="center" style={{ color: "white", cursor: "pointer", marginTop: "10px" }} onClick={handleForgotPasswordClick}>
            Forgot Password?
          </Typography>
        </form>
      </Container>

      {/* Forgot Password Dialog */}
      <Dialog
      open={openDialog}
      onClose={() => setOpenDialog(false)}
      maxWidth="sm"
      fullWidth
    >
   

  <h3 style={{textAlign:'center',fontSize:'22px',color:"#801CA7"}}>Forgot Password</h3>



      <DialogContent>
        <TextField
          fullWidth
          label="Enter your email"
          value={emailForPasswordReset}
          onChange={(e) => setEmailForPasswordReset(e.target.value)}
          variant="outlined"
          margin="normal"
          InputProps={{
            style: { color: "#333" }, // Text color for input
          }}
        />
      </DialogContent>

      <DialogActions>
        <Button
          onClick={() => setOpenDialog(false)}
          color="secondary"
          className={classes.cancelButton}
        >
          Cancel
        </Button>
        <Button
          onClick={handlePasswordResetSubmit}
          color="primary"
          disabled={loading} // Disable the button when loading
          className={classes.button}
        >
          {loading ? "Sending..." : "Submit"} {/* Change button text based on loading */}
        </Button>
      </DialogActions>
    </Dialog>


      <ToastContainer />
    </div>
  );
};

export default SignIn;
